import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import usePageStyles from '../hooks/usePageStyles';
import PageContent from '../components/PageContent/PageContent';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';

function Page(props) {
  const {
    data: { wpPage },
  } = props;
  const {
    title: pageTitle,
    pageStyles,
    template: { pageContent },
  } = wpPage;
  usePageStyles(pageStyles);

  return (
    <section className="full-height">
      <Seo post={wpPage} />
      <HeaderSpacer />
      <Breadcrumbs data={[{ label: pageTitle }]} />
      <PageContent content={pageContent} />
    </section>
  );
}

export default Page;

export const query = graphql`
  query pageQuery($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      seo {
        ...YoastPageSEOContent
      }
      title
      pageStyles {
        ...WpPage_PagestylesFragment
      }
      template {
        ...WpDefaultTemplate_PagecontentFragment
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      pageStyles: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
      }),
      template: PropTypes.shape({
        pageContent: PropTypes.shape({}),
      }),
      seo: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};
